import React from 'react'
import { Container, BlogWrapper, Icon, BlogBg, BlogContent, BlogSection, BlogText, BlogTitle, VideoBg, BlogImage, ImgWrapper, BlogList, BlogVideo, VideoWrapper} from '../Blog/BlogElements';
import Video from '../../videos/bgvideo.mp4';
import { Img } from '../InfoSection/InfoElements';

const index = () => {
  return (
    <>
      <Container>
        <BlogBg>
          <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </BlogBg>
        <BlogWrapper>
          <Icon to='/'>Rafael Garcia Oporto</Icon>
          <BlogContent>
            <BlogTitle>Blog #2:</BlogTitle>
            <BlogTitle>Web Development Using React.js and Styled Components</BlogTitle>
            <BlogText>Last time, we went over some React.js basics, how to set up a project, and a very surface level overview of what a project using styled-components looks like. Today we will build off of this last section, taking a deep dive into how to build a web application that utilizes styled components. To accomplish this, I will use the design and build of this website as our point of reference when talking about how to build certain components. </BlogText>
            <BlogSection>What’s on the agenda?</BlogSection>
            <BlogText>Our goal with this project is to create a portfolio / resume website. Software developer websites are as varied as the individuals that make them, so the details of how you wish to style your website will come down to your preference. However, a few components are present in most portfolios and should be present in your own project. These components include:</BlogText>
            <BlogList>
                <BlogText>- A clean landing page.</BlogText>
                <BlogText>- Body pages that incorporate a mix of images and text to give information about the developer.</BlogText>
                <BlogText>- Navigation tools (usually a header) that take a visitor across the different sections of the website. </BlogText>
                <BlogText>- A footer with additional information and ways of contacting the developer.</BlogText>
            </BlogList>
            <BlogText>This blog will outline the process of creating each of these components on our website, showing how we organize our React.js components and how we build functionality using styled-components.</BlogText>
            <BlogSection>The Landing Page</BlogSection>
            <BlogText>Starting with the (seemingly) simplest component of our site, the landing page will act as the first impression a user has of the website and the developer. Because of this, it is crucial our landing page is clean, easy to read, and has clear navigation to the rest of the site. However, what this actually looks like will come down to personal preference, and how you build your own landing page should show off who you are at a quick glance. For our purposes, we will add the developers name, a short blurb about them, and an image or video in the background.</BlogText>
            <BlogText>When we use styled-components, each webpage is defined by two files: index.js, and elements.js. The elements.js page defines the elements used in the index, as well as styles those elements to look a particular way. The index organizes the elements into a cohesive page with clear functionality. If we were to draw parallels to basic web development, the elements.js file would be closest to a CSS file, while the index is a combination of HTML and JavaScript. Below is a screenshot of the index.js file used to build the landing page on this website. </BlogText>
            <ImgWrapper>
            <BlogImage src={require('./images/landing1.png')} alt='A code snippet showing the index page of the landing page'></BlogImage>
            </ImgWrapper>
            <BlogText>The file contains three sections: the imports, the functions, and the return. Starting with the import section, the import keyword indicates what files, variables, and code are brought in from other files. We must import React from ‘react’ to indicate this page utilizes React.js, otherwise it will be used as simple JavaScript.  Of note, the first word after the import keyword will be the variable name of whatever we are importing. For example, line 2 imports an mp4 file named bgvideo from the videos folder, and is saved under the variable Video. Following the video import are our elements, which we import from the styled-components file. </BlogText>
            <BlogText>We will ignore the LandingSection function for now and focus on the return section. This section builds the webpage, organizing the elements we have imported into a coherent visual experience. As you can see, we wrap everything in a LandingContainer, and have elements that function similar to div tags for each section (LandingBg, LandingContent). Within these section elements we write our content, may it be a video, a header, a button, or some plain text. </BlogText>
            <BlogText>About the VideoBG element, we can see the Video variable we established in the import section being used as the source, as well as other properties that tell the video how to function. These properties should be the same as those of HTML tags due to how styled-components function. This will be addressed in the next paragraph. Lastly, we have the text of the page, made up of a header with the developer’s name, and a short body paragraph. The example shows two options for the body paragraph on the website: one short blurb (commented out), and a one-line quote. I chose to use the quote as it reduces the visual clutter on this specific example.</BlogText>
            <BlogText>Now that we have established how the page is built, we will look at the styled-component file:</BlogText>
            <ImgWrapper>
              <BlogImage src={require('./images/landing2.png')} alt='A code snippet showing the styled-components and css for the landing page'></BlogImage>
            </ImgWrapper>
            <BlogText>Once again, our page contains a series of imports. Note that this page is not importing ‘react’, but rather ‘styled-components’, as the function of the page differs than the one previously examined. Additionally, we are importing a ‘react-scroll’ Link, and a couple icons from the ‘react-icons’ library. The icons are standard arrow icons used on the buttons for a little added flair. The Link, on the other hand, is a bit more complex, as it provides functionality to an element to link to another element on the same page. This will be used to allow us to scroll down to another part of the webpage. We will cover linking different pages when we go over the header. </BlogText>
            <BlogText>In order to define an element, we use the export keyword, followed by const (as the elements do not change) and the variable name. We assign this element a styled.HTML_element value, which tells the browser how it should use our self-defined elements. For example, LandingContainer is defined as styled.div, so the browser will use this element the same way it would an HTML div tag. This, in turn, allows us to use the properties normally associated with a div tag when using our own styled-components, such as the VideoBG element using properties normally associated with a video tag in the previous section. The last section of our element is the styling, enclosed by `’s. The styling uses the same format as CSS, but is limited to styling individual elements rather than whole classes. This may be seen by some as a weakness of styled-components, as we do not have the flexibility of assigning the same tag different styles by specifying an id or a class name, but we can circumvent this by creating multiple elements of the same kind (ie. Classes) or creating different elements for each individual value (ie. ID). </BlogText>
            <BlogText>One type of element that differs from the ones described above are those elements that use values that are imported onto the page. In this example, this would be the Link, as well as the arrow icons: </BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/landing3.png')} alt='A code snippet showing the styled-components and css for the links'></BlogImage>
            </ImgWrapper>
            <BlogText>As these elements are not using HTML tags but rather values from a React library, the assignment of these is a little different. We use styled(React_library_component) to define these types of elements, while still enclosing the styling in around `s. Since we’re in the topic of the Link and icons, lets return to the index.js file for a moment and see how these three are brought into the page:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/landing4.png')} alt='A code snippet showing the LandingButton element with a series of properties'></BlogImage>
            </ImgWrapper>
            <BlogText>The LandingButton component (which is the name of our imported Link) uses a series of properties to define its behavior. The “to” property indicates what component we are linking to (remember, the Link we are using connects components in the same page), the “smooth” property defines if we are scrolling down (true) or snapping (false) to the end location, and “duration” indicates how long the scroll time should be in milliseconds. “Spy” and “exact” both deal with keeping track of the end location, and should both be set to true when we are creating a scroll link, and the “offset” indicates how off we want to be when we scroll. In our case, we want an offset of 80 because the header we use is 80 pixels wide, so the offset ensures we are not covering part of destination page with the header when we click the link. </BlogText>
            <BlogText>Lastly, we have our state function, onHover. This function tells the page which icon it should display, ArrowForward if true, ArrowRight if false. We define what the function return at an earlier section of the index.js file:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/landing5.png')} alt='A code snippet showing the LandingSection function'></BlogImage>
            </ImgWrapper>
            <BlogText>This is a use-state hooks, one of the staples of React.js. Use-state functions toggle between two states, with what the states are being defined by the developer. We define our use-state using const[currentState, stateChangeFunction]= useState(defaultState);. In our case, the state toggles when the button is being hovered, with the default being false (it is not hovered). The function setHover flips the value when it is activated via onHover, turning the false value into true. While this type of hook is a bit confusing at first, it provides lots of visual functionality to developers, allowing them to easily toggle between two states that they can define. </BlogText>
            <BlogText>Altogether, our page will look something like this (minding all the CSS that went into it): </BlogText>
            <ImgWrapper>
            <BlogImage src={require('./images/landing6.png')} alt='A screenshot of the landing page of this website'></BlogImage>
            </ImgWrapper>
            <BlogText>The functionality we have built here is better seen in motion, so I will showcase it in the video at the end of the blog. </BlogText>
            <BlogSection>Body Section</BlogSection>
            <BlogText>Now that we have made a nice landing page, we can begin to build the body of our website. This should include information about the developer, what work they have done, any education or certification they may have, and maybe even a blog such as this one! The body of this webpage is built from copies of the same page, being populated by different data in an external file. This will be the focus of this section, as much of the rest of the page uses similar concepts as those used when building the landing page.</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body1.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>This is the index.js page of our content section. As you can see, it is quite similar in overall structure as the landing page: imports, function, then return, with the return organizing the elements we import from InfoElements.js file. However, there is one key distinction: The page does not have any content directly written on it, instead opting to have placeholder variables that populate the page with information from a separate file, called data.js:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body2.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>This page generates objects with the appropriate properties, matching those required on the previous index page. We indicate which object we want to use in the index.js file for the whole application (not the component index):</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body3.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>We will go over this index page at the end of the tutorial, so don’t worry too much about for now. Just know that when we save data to an external page, we indicate which object we wish to use from that external page at the end of the process when we build the website with all of our components.</BlogText>
            <BlogSection>Navigation</BlogSection>
            <BlogText>Now that we have covered how to build the pages we want to display on our site, we need to create some ways of traveling from one to the other. This website was designed to be a one-page portfolio, so the navigation tools focus on moving across different sections of the page. We have seen how this can be done in the landing page section when discussing the button, but let’s expand on that idea and build a navbar. Below is the return section of the index of the navbar:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body4.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>As we can see, the Navbar is made up of: a logo button, NavLinks, and one NavBtnLink. To understand what these elements do, here are a few snippets of the NavbarElement.js file:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body5.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
                <BlogImage src={require('./images/body6.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
                <BlogImage src={require('./images/body7.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
                <BlogImage src={require('./images/body8.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>Looking at the imports, the ‘react-scroll’ from the landing page makes a return, saved as LinkS. We have another link, however, LinkR which is imported from ‘react-router-dom’. LinkR serves the more traditional link role: it connects different webpages together. The NavLogo and the NavBtnLink are both LinkR’s; on the index.js page, they link to the the home page (“/”) and “/emailme” pages respectively. The rest of the links on our navbar are LinkS, connecting the homepage to different sections of the page. Each link represents a different body page.</BlogText>
            <BlogText>While we are on the topic of links, we can address the footer. The footer in this page contains navigation outside of the webpage, including social media links, email addresses, and links to additional information that was not covered on the main website. These links are also constructed using the ‘react-router-dom’ links, using the “href=” parameter to indicate which external site the icon/button should link to:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body9.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>Lastly, and this is more visual than functional, the react-icons library contains many social media logos that can be imported onto the index.js page. They’re seen in the above example as FaLinkedin, FaTwitter, and FaGithub, and below is the import used to bring them into the project:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/body10.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>Using these icons is an easy way to make your website look clean and professional!</BlogText>
            <BlogSection>Bringing it All Together</BlogSection>
            <BlogText>So now that we have created all our components, we have to build a webpage. In a folder called /pages, we will build our webpage on a file called (surprise, surprise!) index.js. By now, you know the drill: we will import the components from the previous files, create any functions (if any), and return the components in the desired order. Once constructed, our file will look something like this:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/conclusion1.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>As previously mentioned, it is at this step that we import and specify the data that is used for each of our body pages (the InfoSection tags). You will see some components being imported that we did not discuss in the previous sections, such as Projects and Education. These pages are like the InfoSection page, but have stylings unique to them, and thus were made into their own components. The toggle function that is written on this page is used for another component that wasn’t explained prior: Sidebar. Sidebar is the hamburger menu present when viewing the page in a smaller window, and is toggled on and off by the user clicking on it. It is functionally the same as the Navbar, and this blog is long enough as it is, so I opted not to go into detail about it. </BlogText>
            <BlogText>But what if you have multiple pages on your website? Is there another index.js file I have to worry about? Well, not quite. We import all of our pages onto the App.js file, which is autogenerated when you create your project. Once you have finished building all the pages for your website, we import those pages, as well as a BrowserRouter from 'react-router-dom'. We need three key components from this library: Router, Routes, and Route. The Router wraps the whole application, providing functionality. The Routes component wraps all the Route components, indicating where those Route components are located. Each Route represents a webpage, and provides the path to that page:</BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/conclusion2.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogText>The separation between the Router and Routes wrapper allows us to implement a function to every page on the website. In this case, we import the ScrollToTop component, which simply defaults each page to the top when travelling between them. </BlogText>
            <BlogText>Once your Router is all set, you’re done! The default creation of your React project should handle that final import to the index.js that renders the whole application, but here is ours for reference: </BlogText>
            <ImgWrapper>
                <BlogImage src={require('./images/conclusion3.png')} alt='A screenshot of the index page of the infoSection'></BlogImage>
            </ImgWrapper>
            <BlogSection>Closing</BlogSection>
            <BlogText>That about covers all the functionality required to build website using React.js and styled-components! Below is a short video showcasing a lot of the visual components of a website come down to your skill on CSS (or whatever libraries you may find), so play around with how your components look until you find something that fits the image you want to share with others. Hopefully this tutorial was helpful, and do let me know if you end up building something after reading this! </BlogText>
            <VideoWrapper>
              <BlogVideo src="https://youtube.com/embed/GtmVJHk5g54"/>
            </VideoWrapper>
          </BlogContent>
        </BlogWrapper>
      </Container>
    </>
  );
}

export default index