import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    min-height: 692px;
    width: auto;
    height: 850px;
    position: relative;
    bottom: 0;
    right: 0; 
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    background:#0c0c0c;

    :before{
        content: '';
        position: absolute;
        top:0;
        left:0; 
        right:0;
        bottom:0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%,
        rgba(0,0,0,0.6) 100%),
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }

    @media screen and (max-width: 400px){
        height: 950px;
    }
`;

export const EmailBg = styled.div `
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.video `
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`


export const FormWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    z-index: 2;
    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }
`;

export const FormContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 3;
    @media screen and (max-width: 480px){
        padding: 10px;
    }

    @media screen and (max-width: 400px){
        width: 200px;
        justify-content: center;
        margin-left: 10px;
    }
`;

export const Form = styled.form`
    background: #fff;
    max-width: 500px;
    height: 650px;
    width: auto;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    padding: 40px 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

    @media screen and (max-width: 400px){
        padding: 32px 32px;
        height: auto;
    }
`;

export const FormH1 = styled.h1`
    margin-bottom: 40px;
    color: #010101;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
`;

export const FormLabel = styled.label`
    margin-bottom: 4px;
    font-size: 14px;
    color: #010101;
`;

export const FormInput = styled.input`
    padding: 10px 10px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
    background-color: rgba(234, 234, 234, 1)
`;

export const FormBody = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
    height: 250px;
    background-color: rgba(234, 234, 234, 1)
`;

export const FormButton = styled.button`
    background: #010606;
    padding: 16px 0;
    border: none; 
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #FF2BB8;
    }
`;

export const Text = styled. span`
    text-align center;
    margin-top: 24px;
    color: #010101;
    font-size: 14px;
`;

