import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import { Container, FormBody, FormContent, FormInput, FormWrapper, Icon, Form, FormH1, FormLabel, FormButton, EmailBg, VideoBg} from './EmailElements';
import Video from '../../videos/bgvideo.mp4';
import EmailKey from './EmailKey.js';

const Email = () => {

    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevents default refresh by the browser
        emailjs.sendForm(EmailKey.SERVICE_ID, EmailKey.TEMPLATE_ID, form.current, EmailKey.USER_ID)
        .then((result) => {
        alert("Message Sent, I will get back to you shortly", result.text);
        },
        (error) => {
        alert("An error occurred, Please try again", error.text);
        });
        };

  return (
      <>
      <Container>
          <EmailBg>
              <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
          </EmailBg>
          <FormWrapper>
              <Icon to='/'>Rafael Garcia Oporto</Icon>
              <FormContent>
                  <Form action="/" ref={form} onSubmit={handleSubmit}>
                      <FormH1>Thanks for reaching out! How can I help you today?</FormH1>
                      <FormLabel htmlFor='for'>Email</FormLabel>
                      <FormInput type='email' name='email' required></FormInput>
                      <FormLabel htmlFor='for' name = 'subject'>Subject</FormLabel>
                      <FormInput type='text'></FormInput>
                      <FormLabel htmlFor='for'>Message</FormLabel>
                      <FormBody type='text' name='message' required></FormBody>
                      <FormButton type='submit'>Send Email</FormButton>
                  </Form>
              </FormContent>
          </FormWrapper>
      </Container>
      </>
  );
}

export default Email