import React from 'react';
import Email from '../components/Email';
import Footer from '../components/Footer'
const contactme = () => {
  return (
    <>
    <Email/>
    <Footer />
    </>
  );
};

export default contactme;
