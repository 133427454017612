import styled from 'styled-components';

export const ProjectContainer = styled.div`
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    width: auto;

    @media screen and (max-width: 768px){
        height: 1750px;
    }

    @media screen and (max-width: 400px){
        height: 1800px;
    }
`

export const ProjectWrapper = styled.div`
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 40px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
        width: 600px;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }

    @media screen and (max-width: 400px){
        width: 250px;
    }
`

export const ProjectCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transtion: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    @media screen and (max-width: 400px){
        height: 400px;
        text-align: center;
    }

`

export const ProjectIcon = styled.img`
    max-height: 250px;
    max-width: 250px;
    margin-bottom: 10px;
    border-radius: 5px;
    &:hover{
        transform: scale(2.75);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        justify-content: center;
    }

    @media screen and (max-width: 600px){
        width: 150px;
        &:hover{
            transform: scale(2.0);
            transition: all 0.5s ease-in-out;
            cursor: pointer;
            justify-content: center;
        }
    }
`

export const ProjectH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ProjectH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ProjectP = styled.p`
    font-size: 1rem;
    text-align: center; 
`