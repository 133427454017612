import React, {useState} from 'react';
import Video from '../../videos/bgvideo.mp4';
import { LandingContainer, LandingBg, VideoBg, LandingContent, LandingH1, LandingP , LandingBtnWrapper, LandingButton, ArrowForward, ArrowRight} from './LandingElements';
const LandingSection = () => {
    const[hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

  return (
    <LandingContainer id="home">
        <LandingBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </LandingBg>
        <LandingContent>
            <LandingH1>Rafael Garcia Oporto</LandingH1>
            {/* <LandingP>
                Thank you for taking the time to check out my website.
                Look around to learn about myself, what type of work I have 
                done, as well as ways to reach out.
            </LandingP> */}
            <LandingP>                
                Let's build something great together.
            </LandingP>
            <LandingBtnWrapper>
                <LandingButton to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                    Get Started {hover ? <ArrowForward /> : <ArrowRight />}
                </LandingButton>
            </LandingBtnWrapper>
        </LandingContent>
    </LandingContainer>
  );
};

export default LandingSection;
