import React from 'react';
import {SidebarContainer, Icon, CloseIcon, SidebarWrap, SidebarMenu, SidebarLink, SidebarRoute, SideBtnWrap} from './SidebarElement';

const Sidebar = ({isOpen , toggle}) => {
  return (
  <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
          <CloseIcon/>
      </Icon>
      <SidebarWrap>
        <SidebarMenu>
          <SidebarLink to='about' onClick={toggle}>About</SidebarLink>
          <SidebarLink to='projects' onClick={toggle}>Projects</SidebarLink>
          <SidebarLink to='blog' onClick={toggle}>Blog</SidebarLink>
          <SidebarLink to='education' onClick={toggle}>Education</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/contactme">Contact Me</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrap>
  </SidebarContainer>
  ); 
};

export default Sidebar;
