import React from 'react';
import { ProjectContainer, ProjectH1, ProjectWrapper, ProjectCard, ProjectIcon, ProjectH2, ProjectP } from './ProjectElements';
import Icon1 from '../../images/project-inventory.jpg';
import Icon2 from '../../images/project-database.jpg';
import Icon3 from '../../images/project-wordtracker.jpg';

const Projects = () => {
  return(
    <ProjectContainer id='projects'>
        <ProjectH1>Projects</ProjectH1>
            <ProjectWrapper>
            <ProjectCard>
                    <ProjectIcon src={Icon1} />
                    <ProjectH2>Home Inventory System</ProjectH2>
                    <ProjectP>
                        A web application that helps you log what items you have stored in each room of you house.
                        This system is built using HTML, CSS, MySQL, and Java/Java Servlets.
                    </ProjectP>
                </ProjectCard>
                <ProjectCard>
                    <ProjectIcon src={Icon2} />
                    <ProjectH2>Database Search System</ProjectH2>
                    <ProjectP>
                        A web application that displays and searches information taken from data.calgary.ca, 
                        such as school locations, green spaces, or transit routes. This system was built using HTML, CSS, 
                        JavaScript, and JSON.
                    </ProjectP>
                </ProjectCard>
                <ProjectCard>
                    <ProjectIcon src={Icon3} />
                    <ProjectH2>Word Count</ProjectH2>
                    <ProjectP>A Java program that counts the number of times a certain word is used in a piece of
                        text. The goal is to help pick up on repetitive word use, will update it to provide alternative word use.
                    </ProjectP>
                </ProjectCard>
            </ProjectWrapper>
    </ProjectContainer>
  );
};

export default Projects;
