import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Blog from './pages/blog';
import Contact from './pages/contactme';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contactme" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
