/*
Here is where the data we see on the webpage actually exists. 
All other components are templates, but this is where we can add
data without hardcoding it into the template. 

Images taken from unDraw
*/

export const HomeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'About Me',
    headline: 'Hey! My name is Raf',
    description: "I'm an up and coming software developer with a background in zoology and environmental science. I'm a problem solver at heart and delight in solving puzzles, untangling errors in code, and finding the perfect solution for every problem. I believe in building strong communities and elevating members that step up to this important task.",
    closing: "So, how can I help you today?",
    buttonLabel: 'Get in touch',
    imgStart: false,
    img: require('../../images/svg-aboutme.svg'),
    alt: 'About me Image',
    primary: true,
    darkText: true,
    location: '/contactme'
}

export const HomeObjTwo = {
    id: 'projects',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Projects',
    headline: 'Projects',
    description: 'Describe Projects! :)',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-aboutme.svg'),
    alt: 'About me Image',
    primary: false,
    darkText: false
}

export const HomeObjThree = {
    id: 'blog',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'Blog',
    headline: "Want to know what I've been up to lately?",
    description: 'Creative writting is one of my hobbies, may it be short stories or expansive D&D adventures. Blogging provides a way of adding a little of that passion to documenting my learning journey.',
    closing: '',
    buttonLabel: 'Read Blogs',
    imgStart: false,
    img: require('../../images/svg-blog.svg'),
    alt: 'Blogging Image',
    primary: true,
    darkText: true,
    location: '/blog'
}