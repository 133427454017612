import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
min-height: 700px;
width: auto;
height: 90%;
position: relative;
bottom: 0;
right: 0; 
left: 0;
top: 0;
z-index: 1;
overflow: hidden;
background:#0c0c0c;

:before{
    content: '';
    position: absolute;
    top:0;
    left:0; 
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%,
    rgba(0,0,0,0.6) 100%),
    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 2;
`;

export const BlogBg = styled.div `
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
`

export const VideoBg = styled.video `
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;
`


export const BlogWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
@media screen and (max-width: 480px){
    height: 80%;
}
`;

export const Icon = styled(Link)`
diplay: flex;
position: sticky;
margin-left: 32px;
margin-top: 32px;
text-decoration: none;
color: #fff;
font-weight: 700;
font-size: 32px;
z-index: 10;
@media screen and (max-width: 480px){
    margin-left: 16px;
    margin-top: 8px;
}
`;

export const BlogContent = styled.div`
// display: flex;
// flex-direction: column;
justify-content: center;
z-index: 3;
background: #fff;
min-height: 500px;
height: auto;
max-width: 920px;
width: auto;
margin: 0 auto;
padding: 40px 32px;
border-radius: 4px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

@media screen and (max-width: 480px){
    padding: 10px;
}
`;

export const BlogTitle = styled.h1`
text-align: center;
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
`;

export const BlogSection = styled.h2`
color:#FF2BB8;
font-size: 24px;
line-height 16px;
font-weight: 700px;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 20px;
`

export const BlogText = styled.p`
    max-width: 880px;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 24px;
`

export const BlogList = styled.div`
    padding-left: 50px;
`

export const ImgWrapper = styled.div`
padding-left: 100px;
`
export const BlogImage = styled.img`
width: 700px;
height: 100%;
margin: 0 0 10px 0;
padding-left: 10px;
@media screen and (max-width: 760px){
    width: 80%;
}

@media screen and (max-width: 400px){
    width: 50%;
}
`

export const VideoWrapper = styled.div`
padding-left: 50px;
`
export const BlogVideo = styled.iframe`
width: 800px;
height: 450px;
margin: 0 0 10px 0;
@media screen and (max-width: 760px){
    width: 80%;
}

@media screen and (max-width: 400px){
    width: 50%;
`