import React from 'react';
import { EducationContainer, EducationH1, EducationWrapper, EducationCard, EducationIcon, EducationH2, EducationP } from './EducationComponents';
import Icon1 from '../../images/svg-education1.svg';
import Icon2 from '../../images/svg-education2.svg';

const Education = () => {
  return (
    <EducationContainer id='education'>
        <EducationH1>Education and Certification</EducationH1>
            <EducationWrapper>
            <EducationCard>
                    {<EducationIcon src={Icon1} />}
                    <EducationH2>Univerisity of Calgary</EducationH2>
                    <EducationP>
                        Bachelor of Science with a Major in Zoology
                    </EducationP>
                </EducationCard>
                <EducationCard>
                    <EducationIcon src={Icon2} />
                    <EducationH2>Southern Alberta Institute of Technology</EducationH2>
                    <EducationP>
                        Diploma in Software Development
                    </EducationP>
                </EducationCard>
            </EducationWrapper>
    </EducationContainer>
  );
};

export default Education;
