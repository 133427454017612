import React, {useState} from 'react';
import LandingSection from '../components/LandingSection';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import InfoSection from '../components/InfoSection';
import { HomeObjOne, HomeObjTwo, HomeObjThree } from '../components/InfoSection/Data';
import Projects from '../components/Projects';
import Education from '../components/Education';
import Footer from '../components/Footer'
const Home = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return(
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <LandingSection />
        <InfoSection {...HomeObjOne}/>
        <Projects />
        <InfoSection {...HomeObjThree}/>
        <Education />
        <Footer/>
    </>
  );
};

export default Home;
