import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinkItems, FooterLinksWrapper, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements';
const Footer = () => {

const toggleHome = () => {
        scroll.scrollToTop();
    }
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        Rafael Garcia Oporto
                    </SocialLogo>
                    <WebsiteRights>
                    Rafael Garcia Oporto © {new Date().getFullYear()}
                    All rights reserved.
                    </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="//www.linkedin.com/in/rafael-garcia-oporto/" target = "_blank" aria-label="LinkedIn">
                            <FaLinkedin />
                        </SocialIconLink>
                        <SocialIconLink href="//www.twitter.com/oporto_garcia" target = "_blank" aria-label="Twitter">
                            <FaTwitter />
                        </SocialIconLink>
                        <SocialIconLink href="//www.github.com/RGarciaOporto" target = "_blank" aria-label="GitHub">
                            <FaGithub/>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
