import React from 'react';
import Blog from '../components/Blog2'
import Footer from '../components/Footer'
const blog = () => {
  return (
    <>
    <Blog />
    <Footer />
    </>
  );
};

export default blog;
