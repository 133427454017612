import React, {useEffect, useState} from "react";
import {FaBars} from 'react-icons/fa';
import { IconContext } from "react-icons/lib";
import {animateScroll as scroll} from 'react-scroll';
import {Nav, NavbarContainer, NavLogo, HamMenu, NavMenu, NavItem, NavLink, NavBtn, NavBtnLink} from './NavbarElement'
const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav]  = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        }
        else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
     window.addEventListener('scroll', changeNav)  
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return(
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}>
                    Rafael Garcia Oporto
                </NavLogo>
                <HamMenu onClick={toggle}>
                    <FaBars />
                </HamMenu>
                <NavMenu>
                    <NavItem>
                        <NavLink to='about' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                            About
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to='projects' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                            Projects
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to='blog' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                            Blog
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to='education' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                            Education
                        </NavLink>
                    </NavItem>
                </NavMenu>
                <NavBtn>
                    <NavBtnLink to='/contactme'>Contact Me</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
        </IconContext.Provider>
        </>
    );

};

export default Navbar;